import axios from 'axios';

const getList = (params) => axios.get('/relatorios/list', {
  params,
});

const getPage = (params) => axios.get('/relatorios', {
  params,
});

const remove = (id) => axios.delete(`/relatorios/${id}`);

const create = (data) => axios.post('/relatorios', data);

const update = (id, data) => axios.put(`/relatorios/${id}`, data);

export default {
  getList,
  getPage,
  remove,
  create,
  update,
};
