<template>
  <div>
    <px-view-header title="Relatórios" :breadcrumbs="breadcrumbs"/>
    <div class="mt-3">
      <px-cadastro-relatorio-dialog @update="onUpdate">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="primary" class="rounded-tag-small elevation-0">
            <v-icon>mdi-plus</v-icon>
            Relatório
          </v-btn>
        </template>
      </px-cadastro-relatorio-dialog>
    </div>
    <px-relatorios-padroes ref="relatoriosPadroes" class="mt-5"/>
    <px-relatorios-especificos ref="relatoriosEspecificos" />
  </div>
</template>

<script>
import RelatoriosPadroes from '@/modules/relatorios/RelatoriosPadroes.vue';
import pxCadastroRelatorioDialog from '@/modules/relatorios/CadastroRelatorioDialog.vue';
import RelatoriosEspecificos from '@/modules/relatorios/RelatoriosEspecificos.vue';

export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Relatórios',
          disabled: true,
        },
      ],
    };
  },
  methods: {
    onUpdate() {
      this.$refs.relatoriosPadroes.refresh();
      this.$refs.relatoriosEspecificos.refresh();
    },
  },
  components: {
    pxCadastroRelatorioDialog,
    pxRelatoriosPadroes: RelatoriosPadroes,
    pxRelatoriosEspecificos: RelatoriosEspecificos,
  },
};
</script>
