<template>
  <div class="mt-5">
    <px-lista-relatorios-especificos :relatorios="relatorios" :loading="loading"
                                     :on-change-options="onChangeOptions"
                                     :options-list="optionsList"
                                     @update="refresh"/>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination-mixin';
import RelatoriosService from '@/modules/relatorios/relatorios-service';
import ListaRelatoriosEspecificos from '@/modules/relatorios/ListaRelatoriosEspecificos.vue';

export default {
  mixins: [paginationMixin],
  data() {
    return {
      relatorios: {
        content: [],
        total: 0,
      },
      listOptions: {
        page: 1,
        limit: 30,
        offset: 0,
        filter: '',
        sort: '',
        sortDesc: true,
      },
      loading: false,
      breadcrumbs: [
        { text: 'Negócios' },
        { text: 'Cotações' },
      ],
      optionsList: {},
    };
  },
  methods: {
    onChangeOptions(options) {
      this.optionsList = options;
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getRelatorios();
    },
    refresh() {
      this.getRelatorios();
    },
    onClickRefresh() {
      this.getRelatorios();
    },
    resetPage() {
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
    },
    getRelatorios() {
      this.loading = true;
      const {
        limit,
        offset,
      } = this.listOptions;
      return RelatoriosService.getPage({
        limit,
        offset,
        query: this.buildQuery(this.listOptions),
      })
        .then((response) => {
          this.relatorios = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.optionsList = this.pageToOptions(this.listOptions);
    this.listOptions.filter = 'comprador.id is not null';
  },
  components: {
    pxListaRelatoriosEspecificos: ListaRelatoriosEspecificos,
  },
};
</script>
