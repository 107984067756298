<template>
  <div>
    <px-circular-loading v-if="loading"/>
    <v-card v-else class="rounded-card">
      <v-card-title class="font-weight-bold">
        Padrões
      </v-card-title>
      <v-card-text class="text--primary">
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th>Código</th>
              <th>Descrição</th>
              <th>Tipo</th>
              <th>Template ID</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in relatorios" :key="item.id">
              <td>{{ item.id }}</td>
              <td>{{ item.descricao }}</td>
              <td>{{ item.tipoRelatorio }}</td>
              <td>{{ item.templateId }}</td>
              <td>
                <px-cadastro-relatorio-dialog :relatorio-cadastrado="item"
                                              @update="refresh"/>
                <v-btn icon @click="onClickRemove(item)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <px-confirm-dialog ref="confirm"/>
  </div>
</template>
<script>
import RelatoriosService from '@/modules/relatorios/relatorios-service';
import CadastroRelatorioDialog from '@/modules/relatorios/CadastroRelatorioDialog.vue';

export default {
  data() {
    return {
      loading: false,
      relatorios: [],
    };
  },
  methods: {
    refresh() {
      this.getRelatorios();
    },
    getRelatorios() {
      this.loading = true;
      RelatoriosService.getList({
        query: 'comprador.id is null',
      })
        .then((response) => {
          this.relatorios = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removeRelatorio(item) {
      RelatoriosService.remove(item.id)
        .then(() => {
          this.getRelatorios();
          this.$toast('Relatório removido com sucesso', { color: 'success' });
        });
    },
    onClickRemove(item) {
      this.$refs.confirm.open('Remover Relatório',
        `Deseja realmente remover o relatório ${item.descricao}?`)
        .then((confirm) => {
          if (!confirm) {
            return;
          }
          this.removeRelatorio(item);
        });
    },
  },
  created() {
    this.getRelatorios();
  },
  components: {
    pxCadastroRelatorioDialog: CadastroRelatorioDialog,
  },
};
</script>
