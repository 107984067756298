<template>
  <v-card class="mt-3">
    <v-card-text>
      <v-row dense>
        <v-col cols="12" v-if="isMoedaEstrangeira">
          <px-moeda-chip :moeda="moeda"/>
        </v-col>
        <v-col cols="12" sm="4">
          <template v-if="isMoedaEstrangeira">
            <v-row dense no-gutters class="body-1">
              <v-col cols="6">
                Produtos:
              </v-col>
              <v-col cols="6" class="text-right">
                {{
                  compra.totalProdutos |
                    currency(simboloMoeda, casaDecimal, configuracaoFormatacaoMoeda)
                }}
              </v-col>
              <v-col cols="6">
                Frete:
              </v-col>
              <v-col cols="6" class="text-right">
                {{
                  compra.frete.totalTransporte |
                    currency(simboloMoeda, 2, configuracaoFormatacaoMoeda)
                }}
              </v-col>
              <v-col cols="6" class="font-weight-bold">
                Total:
              </v-col>
              <v-col cols="6" class="text-right">
                  <span class="text--primary--color font-weight-bold">
                    {{
                      compra.total |
                        currency(simboloMoeda, casaDecimal, configuracaoFormatacaoMoeda)
                    }}
                  </span>
              </v-col>
              <template v-if="isMoedaEstrangeira && compra.saving">
                <v-col cols="6" class="font-weight-bold">
                  Saving:
                </v-col>
                <v-col cols="6" class="text-right">
                      <span class="text--success--color font-weight-bold">
                        {{
                          compra.saving |
                            currency(simboloMoeda, 2, configuracaoFormatacaoMoeda)
                        }}
                      </span>
                </v-col>
              </template>
            </v-row>
          </template>
        </v-col>
        <v-col cols="12" sm="4"></v-col>
        <v-col cols="12" sm="4">
          <v-row dense no-gutters class="body-1">
            <v-col cols="6">
              Produtos:
            </v-col>
            <v-col cols="6" class="text-right">
              {{ compra.valorMoedaConvertido.totalProdutos | currency('R$', casaDecimal) }}
            </v-col>
            <v-col cols="6">
              Frete:
            </v-col>
            <v-col cols="6" class="text-right">
              {{ compra.frete.totalTransporteConvertido | currency }}
            </v-col>
            <v-col cols="6" class="font-weight-bold">
              Total:
            </v-col>
            <v-col cols="6" class="text-right">
                  <span class="text--primary--color font-weight-bold">
                    {{ compra.valorMoedaConvertido.total | currency('R$', casaDecimal) }}
                  </span>
            </v-col>
            <template v-if="compra.saving">
              <v-col cols="6" class="font-weight-bold">
                Saving:
              </v-col>
              <v-col cols="6" class="text-right">
                      <span class="text--success--color font-weight-bold">
                        {{ compra.valorMoedaConvertido.saving | currency }}
                      </span>
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        show-expand
        hide-default-footer
        disable-pagination
        :headers="headers"
        :items="compra.produtos"
        item-key="id"
        class="elevation-0"
      >
        <template v-slot:item.produto.descricao="{ item }">
          <span>
            <px-span-tooltip v-if="checkCodigo(item.produto)"
                      top
                      :text="labelCodigo(item.produto)"
                      tooltip="Código interno"/>
            {{ item.produto.descricao }}
            <px-anexo v-if="item.arquivos && item.arquivos.length"
                      small read-only dot
                      v-model="item.arquivos"
                      download-base-path="files/download-comprador"
            >
              <template v-slot:activator="{ on }">
                <v-btn small icon
                      v-on="on">
                  <v-icon small>mdi-paperclip</v-icon>
                </v-btn>
              </template>
            </px-anexo>
          </span>
        </template>
        <template v-slot:item.quantidade="{ item }">
          <span>
            {{ item.quantidade | number(getCurrencyFormat(item.produto.casasDecimais)) }}&nbsp;
          {{ item.unidadeMedida.descricao }}
          </span>
        </template>
        <template v-slot:item.precoUnitario="{ item }">
          <div>
            {{
              item.valorMoedaConvertido.precoUnitario
                | currency('R$', item.produto.casasDecimais)
            }}
            <div class="caption" v-if="isMoedaEstrangeira">
              {{
                item.precoUnitario
                  | currency(simboloMoeda,
                  item.produto.casasDecimais,
                  configuracaoFormatacaoMoeda)
              }}
            </div>
          </div>
        </template>
        <template v-slot:item.icms="{ item }">
          <px-imposto
            :imposto="item.impostos"
            show-valor-convertido
            tipo="ICMS"
            :show-aplicacao="false"
            :scale="item.produto.casasDecimais"/>
          <div v-if="isMoedaEstrangeira">
            <px-imposto
              class="caption"
              :imposto="item.impostos"
              :simbolo-moeda="simboloMoeda"
              :configuracao-moeda="configuracaoFormatacaoMoeda"
              tipo="ICMS"
              :show-aplicacao="false"
              :scale="item.produto.casasDecimais"/>
          </div>
        </template>
        <template v-slot:item.ipi="{ item }">
          <px-imposto
            show-valor-convertido
            :imposto="item.impostos"
            tipo="IPI"
            :show-aplicacao="false"
            :scale="item.produto.casasDecimais"/>
          <div v-if="isMoedaEstrangeira">
            <px-imposto
              class="caption"
              :imposto="item.impostos"
              :simbolo-moeda="simboloMoeda"
              :configuracao-moeda="configuracaoFormatacaoMoeda"
              tipo="IPI"
              :show-aplicacao="false"
              :scale="item.produto.casasDecimais"/>
          </div>
        </template>
        <template v-slot:item.total="{ item }">
          <div>
            {{ item.valorMoedaConvertido.total | currency('R$', item.produto.casasDecimais) }}
            <div class="caption" v-if="isMoedaEstrangeira">
              {{
                item.total
                  | currency(simboloMoeda,
                  item.produto.casasDecimais,
                  configuracaoFormatacaoMoeda)
              }}
            </div>
          </div>
          <px-situacao-cotacao-produto :situacao="item.situacao"/>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="grey lighten-4 expanded-item">
            <v-row dense class="mt-3">
              <v-col cols="12" sm="3">
                <span class="caption font-weight-bold">Especificação do produto</span>
                <div>
                  <px-show-large-text :large-text="item.especificacaoProduto"/>
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <span class="caption font-weight-bold">Código de referência do fornecedor</span>
                <div>
                  {{ item.produto.codigoFornecedor | naoInformado }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <span class="caption font-weight-bold">Observação Externa</span>
                <div>
                  <px-show-large-text :large-text="observacaoExterna(item)"/>
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <span class="caption font-weight-bold">Observação Interna</span>
                <div>
                  <px-show-large-text :large-text="observacaoInterna(item)"/>
                </div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="3">
                <span class="caption font-weight-bold">Data de faturamento</span>
                <div>
                  {{ item.dataFaturamento | date | naoInformado }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <span class="caption font-weight-bold">Prazo de entrega</span>
                <div>
                  {{ item.dataPrevisaoEntrega | date | naoInformado }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <span class="caption font-weight-bold">Finalidade da compra</span>
                <div v-if="item.finalidadeCompra">
                  <px-finalidade-compra-value :finalidade-compra="item.finalidadeCompra" />
                </div>
                <div v-else>
                  Não informado
                </div>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { CasaDecimalUtils } from 'px-components';
import moedaMixin from '@/mixins/moeda-mixin';

export default {
  mixins: [moedaMixin],
  props: {
    compra: Object,
  },
  data() {
    return {
      headers: [
        {
          text: 'Produto',
          sortable: false,
          value: 'produto.descricao',
        },
        {
          text: 'Quantidade',
          value: 'quantidade',
          sortable: false,
        },
        {
          text: 'Preço unitário',
          value: 'precoUnitario',
        },
        {
          text: 'ICMS',
          value: 'icms',
          sortable: false,
        },
        {
          text: 'IPI',
          value: 'ipi',
          sortable: false,
        },
        {
          text: 'Total',
          value: 'total',
        },
        {
          text: '',
          value: 'options',
        },
        {
          text: '',
          value: 'data-table-expand',
        },
      ],
    };
  },
  computed: {
    moeda() {
      return this.compra.moeda;
    },
    getCurrencyFormat() {
      return (item) => CasaDecimalUtils.getCurrencyFormat(item);
    },
    casaDecimal() {
      const mappedProdutos = this.compra.produtos.map((produto) => produto.produto);
      return CasaDecimalUtils.getCasaDecimal(mappedProdutos);
    },
    checkCodigo() {
      return (item) => item.codigo;
    },
    labelCodigo() {
      return (item) => `${item.codigo} -`;
    },
    observacaoExterna() {
      return (produto) => produto.observacaoExterna ?? produto.produto.observacaoExterna;
    },
    observacaoInterna() {
      return (produto) => produto.observacaoInterna ?? produto.produto.observacaoInterna;
    },
  },
};
</script>
