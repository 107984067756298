<template>
  <v-list>
    <template v-for="(produto, index) in cotacao.produtos">
      <v-list-item :key="produto.id">
        <v-list-item-content>
          <v-list-item-title>
            <px-situacao-cotacao-icon :situacao="produto.situacao"/>
            <px-span-tooltip
                    top
                    :text="labelCodigo(produto.produto)"
                    tooltip="Código interno"/>
            {{ produto.produto.descricao }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <px-span-tooltip bottom
                             :text="labelCodigoFornecedor(produto.produto)"
                             tooltip="Código de referência do fornecedor"/>
            {{ produto.quantidade | number(getCurrencyFormat(produto.produto.casasDecimais)) }}
            {{ produto.unidadeMedida.descricao }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <px-anexo read-only
                    v-model="produto.arquivos"
                    download-base-path="files/download-comprador"/>
        </v-list-item-action>
        <v-list-item-action>
          <px-detalhes-produto
              :produto="produto"
              show-projeto
              show-centro-custo
              show-obs-externa
              show-obs-interna
              show-f/>
        </v-list-item-action>
      </v-list-item>
      <v-divider v-if="index !== (cotacao.produtos.length - 1)"
        :key="`v-div${produto.id}`"/>
    </template>
  </v-list>
</template>

<script>
import { CasaDecimalUtils } from 'px-components';

export default {
  props: {
    cotacao: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getCurrencyFormat() {
      return (item) => CasaDecimalUtils.getCurrencyFormat(item);
    },
    labelCodigo() {
      return (item) => (item.codigo ? `${item.codigo} -` : '');
    },
    labelCodigoFornecedor() {
      return (item) => (item.codigoFornecedor ? `${item.codigoFornecedor} - ` : '');
    },
  },
};
</script>
