<template>
  <v-navigation-drawer
    app
    temporary
    width="300px"
    :value="value"
    @input="updateNavigation"
  >
    <v-list-item>
      <v-img max-width="70px" src="../../assets/logo-azul-sem-fundo.png"/>
      <v-list-item-content>
        <v-list-item-title class="title">
          <strong>PROXPECT</strong>
        </v-list-item-title>
        <v-list-item-subtitle>
          Bem-vindo!
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider/>
    <v-list dense v-if="hasRoleSysAdmin">
      <v-list-group
        active-class="grey--text text--darken-2"
        value="true"
      >
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon>mdi-account-lock</v-icon>
          </v-list-item-icon>
          <v-list-item-title dense>Marketplace</v-list-item-title>
        </template>
        <v-list-item
          link
          class="group-item"
          :to="`/marketplace/compras`"
          active-class="orange--text"
        >
          <v-list-item-icon>
            <v-icon>mdi-currency-usd</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Compras</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          class="group-item"
          :to="`/marketplace/cotacoes`"
          active-class="orange--text"
        >
          <v-list-item-icon>
            <v-icon>mdi-email-fast-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Cotações</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          class="group-item"
          :to="`/marketplace/carrinhos`"
          active-class="orange--text"
        >
          <v-list-item-icon>
            <v-icon>mdi-cart-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Carrinhos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          class="group-item"
          :to="`/ofertas`"
          active-class="orange--text"
        >
          <v-list-item-icon>
            <v-icon>mdi-cash-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Ofertas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          class="group-item"
          :to="`/configuracoes-venda`"
          active-class="orange--text"
        >
          <v-list-item-icon>
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Configurações de venda</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          class="group-item"
          :to="`/configuracoes-frete`"
          active-class="orange--text"
        >
          <v-list-item-icon>
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Configurações de frete</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          class="group-item"
          :to="`/marketplace/alertas`"
          active-class="orange--text"
        >
          <v-list-item-icon>
            <v-icon>mdi-bell-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Alertas de preço</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          class="group-item"
          :to="`/marketplace/banners`"
          active-class="orange--text"
        >
          <v-list-item-icon>
            <v-icon>mdi-play-box-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Banners</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          class="group-item"
          :to="`/departamentos`"
          active-class="orange--text"
        >
          <v-list-item-icon>
            <v-icon>mdi-lightbulb-group</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Departamentos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          class="group-item"
          :to="`/grupos`"
          active-class="orange--text"
        >
          <v-list-item-icon>
            <v-icon>mdi-group</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Grupos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          class="group-item"
          :to="`/categorias`"
          active-class="orange--text"
        >
          <v-list-item-icon>
            <v-icon>mdi-tag</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Categorias</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          class="group-item"
          :to="`/marketplace/grades`"
          active-class="orange--text"
        >
          <v-list-item-icon>
            <v-icon>mdi-grid</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Grades</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          class="group-item"
          :to="`/formas-pagamento`"
          active-class="orange--text"
        >
          <v-list-item-icon>
            <v-icon>mdi-credit-card-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Formas de pagamento</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          class="group-item"
          :to="`/configuracoes-meio-pagamento`"
          active-class="orange--text"
        >
          <v-list-item-icon>
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Configurações de meio de pagamento</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          class="group-item"
          :to="`/dicionarios`"
          active-class="orange--text"
        >
          <v-list-item-icon>
            <v-icon>mdi-book-open</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dicionarios</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
    <v-list dense>
      <v-subheader>NEGÓCIOS</v-subheader>
      <v-list-item
        v-if="hasRoleSysAdmin"
        active-class="orange--text"
        link
        to="/cotacoes"
      >
        <v-list-item-icon>
          <v-icon>mdi-currency-usd</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Cotações</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="hasRoleSysAdmin"
        active-class="orange--text"
        link
        to="/compras"
      >
        <v-list-item-icon>
          <v-icon>mdi-currency-usd</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Compras</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="hasRoleSysAdmin"
        active-class="orange--text"
        link
        to="/cotacoes-frete"
      >
        <v-list-item-icon>
          <v-icon>mdi-currency-usd</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Cotações de frete</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="hasRoleSysAdmin"
        active-class="orange--text"
        link
        to="/contratacoes-fretes"
      >
        <v-list-item-icon>
          <v-icon>mdi-truck-check</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Contratações de frete</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="hasRoleSysAdmin"
        active-class="orange--text"
        link
        to="/fornecedores"
      >
        <v-list-item-icon>
          <v-icon>mdi-factory</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Fornecedores</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="hasRoleSysAdmin"
        active-class="orange--text"
        link
        to="/transportadoras"
      >
        <v-list-item-icon>
          <v-icon>mdi-truck</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Transportadoras</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        active-class="orange--text"
        link
        to="/entidades"
      >
        <v-list-item-icon>
          <v-icon>mdi-domain theme--ligh</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Entidades</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="hasRoleSysAdmin"
        active-class="orange--text"
        link
        to="/events"
      >
        <v-list-item-icon>
          <v-icon>mdi-format-list-bulleted</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Eventos</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="hasRoleSysAdmin"
        active-class="orange--text"
        link
        to="/relatorios"
      >
        <v-list-item-icon>
          <v-icon>mdi-file-chart-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Relatórios</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <div class="pa-2" align="right">
        <v-btn text icon @click="closeNavigation">
          Fechar
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['value', 'updateNavigation'],
  computed: {
    ...mapGetters('user', ['user', 'hasRoleSysAdmin']),
  },
  methods: {
    closeNavigation() {
      this.updateNavigation(false);
    },
  },
};
</script>

<style scoped>
.group-item {
  padding-left: 30px !important;
}
</style>
