<template>
  <div>
    <px-view-header title="Compras" :breadcrumbs="breadcrumbs"/>
    <div class="mt-5">
      <listagem-compra-header :on-click-refresh="onClickRefresh"
                               :on-change-filter="onChangeFilter"/>
      <lista-compra :compras="compras"
                    :loading="loading"
                    :on-change-options="onChangeOptions"
                    :options-list="optionsList"/>
    </div>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination-mixin';
import ListaCompra from './ListaCompra.vue';
import ListagemCompraHeader from './ListagemCompraHeader.vue';
import ComprasListagemService from './service/compras-listagem-service';

export default {
  mixins: [paginationMixin],
  methods: {
    onChangeFilter(filter) {
      this.listOptions.filter = filter;
      this.resetPage();
      this.getCompras();
    },
    onChangeOptions(options) {
      this.optionsList = options;
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getCompras();
    },
    onClickRefresh() {
      this.getCompras();
    },
    resetPage() {
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
    },
    getCompras() {
      this.loading = true;
      const { limit, offset } = this.listOptions;
      return ComprasListagemService.getCompras({
        limit,
        offset,
        query: this.buildQuery(this.listOptions),
      })
        .then((response) => {
          this.compras = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  components: {
    ListaCompra,
    ListagemCompraHeader,
  },
  data() {
    return {
      compras: {
        content: [],
        total: 0,
      },
      listOptions: {
        page: 1,
        limit: 100,
        offset: 0,
        filter: '',
        sort: '',
        sortDesc: true,
      },
      loading: false,
      breadcrumbs: [
        { text: 'Negócios' },
        { text: 'Compras' },
      ],
      optionsList: {},
    };
  },
  created() {
    this.optionsList = this.pageToOptions(this.listOptions);
  },
};
</script>
