import Main from '@/modules/main/Main.vue';
import Header from '@/modules/header/Header.vue';
import MinhaConta from '@/modules/minha-conta/MinhaConta.vue';
import Cotacoes from '@/modules/cotacao/listagem/ListagemCotacao.vue';
import CotacaoDetalhes from '@/modules/cotacao/detalhes/CotacaoDetalhes.vue';
import Events from '@/modules/events/Events.vue';
import ListagemCompra from '@/modules/compras/listagem/ListagemCompra.vue';
import CompraDetalhes from '@/modules/compras/detalhes/CompraDetalhes.vue';
import ListagemTransportadoras from '@/modules/transportadoras/ListagemTransportadoras.vue';
import ListagemCotacaoFrete from '@/modules/cotacoes-frete/ListagemCotacaoFrete.vue';
import Transportadora from '@/modules/transportadoras/detalhes/Transportadora.vue';
import CotacaoFreteDetalhes from '@/modules/cotacoes-frete/detalhes/CotacaoFreteDetalhes.vue';
import ListagemFornecedor from '@/modules/fornecedores/ListagemFornecedor.vue';
import Fornecedor from '@/modules/fornecedores/detalhes/Fornecedor.vue';
import ContratacaoListagem from '@/modules/contracoes-frete/ContratacaoListagem.vue';
import ContratacaoDetalhes from '@/modules/contracoes-frete/detalhes/ContratacaoDetalhes.vue';
import ListagemEntidades from '@/modules/entidades/listagem/ListagemEntidades.vue';
import ListagemCompradores from '@/modules/entidades/compradores/ListagemCompradores.vue';
import ListagemUsuarios from '@/modules/entidades/usuarios/ListagemUsuarios.vue';
import Departamentos from '@/modules/marketplace/departamentos/Departamentos.vue';
import Grupos from '@/modules/marketplace/grupos/Grupos.vue';
import Categorias from '@/modules/marketplace/categoria/Categorias.vue';
import MktplaceListagemCompra from '@/modules/marketplace/compras/listagem/ListagemCompra.vue';
import Compra from '@/modules/marketplace/compras/detalhes/Compra.vue';
import ListagemBanner from '@/modules/marketplace/banner/ListagemBanner.vue';
import CadastroBanner from '@/modules/marketplace/banner/cadastro/CadastroBanner.vue';
import DicionarioDados from '@/modules/marketplace/dicionario-dados/DicionarioDados.vue';
import Grades from '@/modules/marketplace/grades/Grades.vue';
import CadastroGrade from '@/modules/marketplace/grades/cadastro/CadastroGrade.vue';
import FormasPagamento from '@/modules/marketplace/formas-pagamento/FormasPagamento.vue';
import Ofertas from '@/modules/marketplace/ofertas/Ofertas.vue';
import ListagemCategorias
from '@/modules/entidades/integracao/climba/categorias/ListagemCategorias.vue';
import OfertaEdit from '@/modules/marketplace/ofertas/OfertaEdit.vue';
import AlertaPreco from '@/modules/marketplace/alertas-preco/AlertaPreco.vue';
import CadastroConfiguracaoVenda
from '@/modules/marketplace/configuracao-venda/edicao/CadastroConfiguracaoVenda.vue';
import ConfiguracoesFrete from '@/modules/marketplace/configuracao-frete/ConfiguracoesFrete.vue';
import ConfiguracoesVenda from '@/modules/marketplace/configuracao-venda/ConfiguracoesVenda.vue';
import CadastroConfiguracaoFrete
from '@/modules/marketplace/configuracao-frete/edicao/CadastroConfiguracaoFrete.vue';
import CadastroFornecedor from '@/modules/fornecedores/cadastro/CadastroFornecedor.vue';
import ConfiguracaoMeioPagamento from '@/modules/marketplace/configuracao-meio-pagamento/ConfiguracaoMeioPagamento.vue';
import CadastroConfiguracaoMeioPagamento from '@/modules/marketplace/configuracao-meio-pagamento/CadastroConfiguracaoMeioPagamento.vue';
import CotacoesMarketplace from '@/modules/marketplace/cotacoes/CotacoesMarketplace.vue';
import Carrinhos from '@/modules/marketplace/carrinhos/Carrinhos.vue';
import CarrinhoDetalhes from '@/modules/marketplace/carrinhos/detalhes/CarrinhoDetalhes.vue';

import store from '@/store';
import { INITIALIZE_FROM_COOKIES_AC } from '@/store/auth/actions.type';
import Relatorios from '@/modules/relatorios/Relatorios.vue';

const beforeEnterAuth = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    if (store.getters.tokenFromStorage) {
      store.dispatch(INITIALIZE_FROM_COOKIES_AC);
      return next();
    }
    return next('/login');
  }
  return next();
};

export default {
  path: '/',
  name: 'main',
  redirect: { name: 'main.cotacoes' },
  components: {
    default: Main,
    header: Header,
  },
  children: [
    {
      path: 'minha-conta',
      name: 'main.minha-conta',
      components: {
        default: MinhaConta,
      },
    },
    {
      path: 'cotacoes',
      name: 'main.cotacoes',
      components: {
        default: Cotacoes,
      },
    },
    {
      path: 'cotacao/:id/detalhes',
      name: 'main.cotacoes.detalhes',
      components: {
        default: CotacaoDetalhes,
      },
    },
    {
      path: 'entidades',
      name: 'main.entidades',
      components: {
        default: ListagemEntidades,
      },
    },
    {
      path: 'entidades/:id/categorias-climba',
      name: 'main.entidades.categoriasclimba',
      components: {
        default: ListagemCategorias,
      },
    },
    {
      path: 'entidades/:id/compradores',
      name: 'main.entidades.compradores',
      components: {
        default: ListagemCompradores,
      },
    },
    {
      path: 'entidades/:entidade/compradores/:company/usuarios',
      name: 'main.compradores.usuarios',
      components: {
        default: ListagemUsuarios,
      },
    },
    {
      path: 'entidades/:entidade/transportadores/:company/usuarios',
      name: 'main.transportadores.usuarios',
      components: {
        default: ListagemUsuarios,
      },
    },
    {
      path: 'compras',
      name: 'main.compras',
      components: {
        default: ListagemCompra,
      },
    },
    {
      path: 'compra/:id/detalhes',
      name: 'main.compras.detalhes',
      components: {
        default: CompraDetalhes,
      },
    },
    {
      path: 'events',
      name: 'main.events',
      components: {
        default: Events,
      },
    },
    {
      path: 'transportadoras',
      name: 'main.transportadoras',
      components: {
        default: ListagemTransportadoras,
      },
    },
    {
      path: 'transportadora/:id',
      name: 'main.transportadoras.detalhes',
      components: {
        default: Transportadora,
      },
    },
    {
      path: 'fornecedores',
      name: 'main.fornecedores',
      components: {
        default: ListagemFornecedor,
      },
    },
    {
      path: 'fornecedor/:id',
      name: 'main.fornecedores.detalhes',
      components: {
        default: Fornecedor,
      },
    },
    {
      path: 'fornecedor/:id/cadastro',
      name: 'main.fornecedores.edicao',
      components: {
        default: CadastroFornecedor,
      },
    },
    {
      path: 'fornecedor/cadastro/novo',
      name: 'main.fornecedores.cadastro',
      components: {
        default: CadastroFornecedor,
      },
    },
    {
      path: 'cotacoes-frete',
      name: 'main.cotacoes-frete',
      components: {
        default: ListagemCotacaoFrete,
      },
    },
    {
      path: 'cotacao-frete/:id',
      name: 'main.cotacoes-frete.detalhes',
      components: {
        default: CotacaoFreteDetalhes,
      },
    },
    {
      path: 'contratacoes-fretes',
      name: 'main.contratacoes-frete',
      components: {
        default: ContratacaoListagem,
      },
    },
    {
      path: 'contratacoes-fretes/:id',
      name: 'main.contratacoes-frete/detalhes',
      components: {
        default: ContratacaoDetalhes,
      },
    },
    {
      path: 'departamentos',
      name: 'main.departamentos',
      components: {
        default: Departamentos,
      },
    },
    {
      path: 'grupos',
      name: 'main.grupos',
      components: {
        default: Grupos,
      },
    },
    {
      path: 'categorias',
      name: 'main.categorias',
      components: {
        default: Categorias,
      },
    },
    {
      path: 'marketplace/compras',
      name: 'main.marketplace.compras',
      components: {
        default: MktplaceListagemCompra,
      },
    },
    {
      path: 'marketplace/compras/:id',
      name: 'main.marketplace.compra.detalhes',
      components: {
        default: Compra,
      },
    },
    {
      path: 'marketplace/banners',
      name: 'main.marketplace.banners',
      components: {
        default: ListagemBanner,
      },
    },
    {
      path: 'marketplace/banners/cadastro',
      name: 'main.marketplace.banner.cadastro',
      components: {
        default: CadastroBanner,
      },
    },
    {
      path: 'marketplace/banners/:id',
      name: 'main.marketplace.banner.edicao',
      components: {
        default: CadastroBanner,
      },
    },
    {
      path: 'marketplace/grades',
      name: 'main.marketplace.grades',
      components: {
        default: Grades,
      },
    },
    {
      path: 'marketplace/grades/cadastro',
      name: 'main.marketplace.grades.cadastro',
      components: {
        default: CadastroGrade,
      },
    },
    {
      path: 'marketplace/grades/:id',
      name: 'main.marketplace.grades.edicao',
      components: {
        default: CadastroGrade,
      },
    },
    {
      path: 'marketplace/alertas',
      name: 'main.marketplace.alertas',
      components: {
        default: AlertaPreco,
      },
    },
    {
      path: 'dicionarios',
      name: 'main.dicionarios',
      components: {
        default: DicionarioDados,
      },
    },
    {
      path: 'formas-pagamento',
      name: 'main.formas-pagamento',
      components: {
        default: FormasPagamento,
      },
    },
    {
      path: 'ofertas',
      name: 'main.ofertas',
      components: {
        default: Ofertas,
      },
    },
    {
      path: 'ofertas/:id',
      name: 'main.ofertas.update',
      components: {
        default: OfertaEdit,
      },
    },
    {
      path: 'configuracoes-venda',
      name: 'main.configuracoes-venda',
      components: {
        default: ConfiguracoesVenda,
      },
    },

    {
      path: 'configuracoes-venda/:id',
      name: 'main.configuracoes-venda.update',
      components: {
        default: CadastroConfiguracaoVenda,
      },
    },
    {
      path: 'configuracoes-frete',
      name: 'main.configuracoes-frete',
      components: {
        default: ConfiguracoesFrete,
      },
    },
    {
      path: 'configuracoes-frete/:id',
      name: 'main.configuracoes-frete.update',
      components: {
        default: CadastroConfiguracaoFrete,
      },
    },
    {
      path: 'configuracoes-meio-pagamento',
      name: 'main.configuracoes-meio-pagamento',
      components: {
        default: ConfiguracaoMeioPagamento,
      },
    },
    {
      path: '/configuracoes-meio-pagamento/cadastro',
      name: 'main.configuracoes-meio-pagamento.cadastro',
      components: {
        default: CadastroConfiguracaoMeioPagamento,
      },
    },
    {
      path: '/configuracoes-meio-pagamento/:id',
      name: 'main.configuracoes-meio-pagamento.edicao',
      components: {
        default: CadastroConfiguracaoMeioPagamento,
      },
    },
    {
      path: 'marketplace/cotacoes',
      name: 'main.marketplace.cotacoes',
      components: {
        default: CotacoesMarketplace,
      },
    },
    {
      path: 'marketplace/carrinhos',
      name: 'main.marketplace.carrinhos',
      components: {
        default: Carrinhos,
      },
    },
    {
      path: 'marketplace/carrinhos/:id',
      name: 'main.marketplace.carrinhos.detalhes',
      components: {
        default: CarrinhoDetalhes,
      },
    },
    {
      path: 'relatorios',
      name: 'main.relatorios',
      components: {
        default: Relatorios,
      },
    },
  ],
  beforeEnter: beforeEnterAuth,
};
