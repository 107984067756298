<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="relatorios.content"
      class="elevation-1 mt-5 rounded-card"
      :loading="loading"
      :server-items-length="relatorios.total"
      @update:options="updateOptions"
      :footer-props="footerOptions"
      no-data-text="Nenhum relatório encontrado"
      loading-text="Carregando relatórios..."
      :options="optionsList"
    >
      <template v-slot:top>
        <div class="px-3 pt-5 pb-5 title font-weight-bold">
          Específicos
        </div>
      </template>
      <template v-slot:item.comprador.nome="{ item }">
        <px-span-tooltip top :text="comprador(item)" :tooltip="item.comprador.pessoa.nome"/>
      </template>
      <template v-slot:item.options="{ item }">
        <px-cadastro-relatorio-dialog :relatorio-cadastrado="item"
                                      @update="emitUpdate"/>
        <v-btn icon @click="onClickRemove(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <px-confirm-dialog ref="confirm"/>
  </div>
</template>

<script>
import Vue from 'vue';
import pxCadastroRelatorioDialog from '@/modules/relatorios/CadastroRelatorioDialog.vue';
import RelatoriosService from '@/modules/relatorios/relatorios-service';

export default {
  components: { pxCadastroRelatorioDialog },
  props: {
    relatorios: Object,
    default: [],
    loading: Boolean,
    onChangeOptions: {
      type: Function,
      required: true,
    },
    optionsList: Object,
  },
  data() {
    return {
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 30, 50, 100],
        itemsPerPageText: 'Linhas por página',
      },
      headers: [
        {
          text: 'Código',
          value: 'id',
        },
        {
          text: 'Descrição',
          value: 'descricao',
        },
        {
          text: 'Empresa',
          value: 'comprador.nome',
        },
        {
          text: 'Tipo',
          value: 'tipoRelatorio',
        },
        {
          text: 'Template ID',
          value: 'templateId',
        },
        {
          text: null,
          sortable: false,
          value: 'options',
        },
      ],
    };
  },
  computed: {
    comprador() {
      return (item) => Vue.filter('truncate')(item.comprador.pessoa.nome, 30);
    },
  },
  methods: {
    updateOptions(options) {
      this.onChangeOptions(options);
    },
    emitUpdate() {
      this.$emit('update');
    },
    removeRelatorio(item) {
      RelatoriosService.remove(item.id)
        .then(() => {
          this.emitUpdate();
          this.$toast('Relatório removido com sucesso', { color: 'success' });
        });
    },
    onClickRemove(item) {
      this.$refs.confirm.open('Remover Relatório',
        `Deseja realmente remover o relatório ${item.descricao}?`)
        .then((confirm) => {
          if (!confirm) {
            return;
          }
          this.removeRelatorio(item);
        });
    },
  },
};
</script>
