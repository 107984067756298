<template>
  <px-custom-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
        <v-btn icon v-on="on">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </slot>
    </template>
    <template v-slot:title>
      Cadastrar relatório
    </template>
    <template>
      <v-row dense class="mt-5">
        <v-col cols="12">
          <px-select-enum dense
                          class="required rounded-tag-small"
                          v-model="relatorio.tipoRelatorio"
                          :items="tiposRelatorio"
                          :error="$v.relatorio.tipoRelatorio.$error"
                          @blur="$v.relatorio.tipoRelatorio.$touch()"
                          label="Tipo do relatório"/>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            dense
            outlined
            hide-details
            autocomplete="off"
            class="required rounded-tag-small"
            label="Descrição"
            :error="$v.relatorio.descricao.$error"
            @blur="$v.relatorio.descricao.$touch()"
            v-model="relatorio.descricao"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            dense
            outlined
            hide-details
            autocomplete="off"
            class="required rounded-tag-small"
            label="Código do template"
            :error="$v.relatorio.templateId.$error"
            @blur="$v.relatorio.templateId.$touch()"
            v-model="relatorio.templateId"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <px-autocomplete-company required
                                   dense
                                   clearable
                                   class="rounded-tag-small"
                                   v-model="relatorio.comprador"/>
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <div class="mt-5">
        <v-btn color="primary"
               @click="onClickSave"
               :loading="loading">
          Salvar
        </v-btn>
        <v-btn text @click="close">Cancelar</v-btn>
      </div>
    </template>
  </px-custom-dialog>
</template>

<script>
import { TipoRelatorio } from 'px-business-components';
import { required } from 'vuelidate/lib/validators';
import RelatoriosService from '@/modules/relatorios/relatorios-service';

export default {
  props: {
    relatorioCadastrado: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      tiposRelatorio: TipoRelatorio.values,
      relatorio: {
        comprador: null,
        tipoRelatorio: null,
        descricao: null,
        templateId: null,
      },
    };
  },
  methods: {
    saveRelatorio(payload) {
      if (this.relatorioCadastrado) {
        return RelatoriosService.update(this.relatorioCadastrado.id, payload);
      }
      return RelatoriosService.create(payload);
    },
    onClickSave() {
      this.loading = true;
      const {
        comprador, tipoRelatorio, descricao, templateId,
      } = this.relatorio;
      const payload = {
        id: this.relatorioCadastrado ? this.relatorioCadastrado.id : undefined,
        comprador: comprador ? {
          id: comprador.id,
        } : undefined,
        entidadeComprador: comprador ? {
          id: comprador.entidade.id,
        } : undefined,
        tipoRelatorio,
        descricao,
        templateId,
      };

      this.saveRelatorio(payload)
        .then(() => {
          this.$toast('Relatório salvo com sucesso', { color: 'success' });
          this.$emit('update');
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.dialog = false;
    },
  },
  watch: {
    dialog(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      if (this.relatorioCadastrado) {
        this.relatorio = { ...this.relatorioCadastrado };
      } else {
        this.relatorio = {
          comprador: null,
          tipoRelatorio: null,
          descricao: null,
          templateId: null,
        };
      }
      this.$v.$reset();
    },
  },
  validations: {
    relatorio: {
      tipoRelatorio: {
        required,
      },
      descricao: {
        required,
      },
      templateId: {
        required,
      },
    },
  },
};
</script>
